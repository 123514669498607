import React from 'react';

const Star = (props) => (
  <svg viewBox="0 0 14 16" version="1.1" width="14" height="16" role="img">
    <path fillRule="evenodd" d="M14 6l-4.9-.64L7 1 4.9 5.36 0 6l3.6 3.26L2.67 14 7 11.67 11.33 14l-.93-4.74L14 6z"/>
  </svg>
);

// https://www.iconfont.cn/search/index?searchType=icon&q=star
export const HalfStar = (props) => (
  <svg viewBox="0 0 1024 1024" version="1.1"  width="16" height="16">
    <path d="M785.352203 933.397493c-4.074805 0-8.151657-0.970094-11.833513-3.007497l-261.311471-142.488225L250.942821 930.388972c-8.343015 4.559852-18.527982 3.8814-26.28669-1.599428-7.760754-5.5279-11.640108-14.987343-10.088776-24.347524l47.578622-285.365306L72.563154 429.470355c-6.594185-6.547113-8.971325-16.295128-6.110161-25.122167 2.814092-8.850575 10.379395-15.397688 19.546172-16.949021l285.512662-47.577598 118.529557-236.989529c4.172019-8.391111 12.803607-13.701047 22.165836-13.701047 9.359158 0 17.992793 5.309936 22.163789 13.701047l118.529557 236.989529 285.511639 47.577598c9.217942 1.551332 16.73208 8.051373 19.593244 16.949021 2.813069 8.875135 0.48607 18.575054-6.109138 25.122167L762.264369 619.077737l47.577598 285.365306c1.50119 9.360182-2.37714 18.819624-10.087753 24.347524C795.487028 931.797042 790.394033 933.397493 785.352203 933.397493zM512.206196 734.747153c4.073782 0 8.196683 0.968048 11.880585 3.006474l228.379431 124.545574-41.268916-247.634983c-1.308809-7.90504 1.257643-16.005532 6.932899-21.63167l164.55787-164.555823-250.398934-41.709961c-7.855922-1.308809-14.549368-6.304589-18.140149-13.408381l-101.942787-203.915249" />
  </svg>
);

export const Stars = (props) => (
  <svg viewBox="0 0 1024 1024" version="1.1" width="18" height="18">
    <path fill="#f5c55f" d="M 757.39087,486.83814 568.21799,459.22035 483.55951,287.37628 c -7.58135,-15.34322 -22.38305,-23.10509 -37.36525,-23.10509 -14.8017,0 -29.42288,7.58136 -37.00424,23.10509 l -84.65847,171.84407 -189.17288,27.61779 c -33.9356,4.87373 -47.654239,46.7517 -22.92458,70.75932 L 249.44002,691.17374 216.9485,879.9856 c -4.69322,26.89576 16.78729,48.55678 40.79491,48.55678 6.3178,0 12.99661,-1.44407 19.31441,-4.87373 l 169.31695,-89.17119 169.31695,89.17119 c 6.31779,3.24915 12.8161,4.87373 19.1339,4.87373 24.00762,0 45.48813,-21.48051 40.97542,-48.55678 L 643.49002,691.17374 780.49595,557.59746 c 24.54916,-24.00762 10.83051,-65.88559 -23.10508,-70.75932 z m -174.5517,142.42119 -32.67203,31.76949 7.76187,44.94661 16.96779,98.37712 -88.26864,-46.39068 -40.25339,-21.3 -40.4339,21.3 -88.26864,46.39068 16.96779,-98.37712 7.76187,-44.94661 -32.67204,-31.76949 -71.66186,-69.85678 98.91864,-14.44068 45.12712,-6.4983 20.21695,-40.79492 44.04407,-89.5322 44.04407,89.5322 20.21695,40.79492 45.12711,6.4983 98.91865,14.44068 z M 706.30697,293.15255 754.50273,196.94153 850.71375,148.74577 754.50273,100.55 706.30697,4.338983 658.11121,100.55 l -96.21102,48.19577 96.21102,48.19576 z m 259.9322,57.76271 -28.88135,-57.76271 -28.88136,57.76271 -57.76271,28.88136 57.76271,28.88135 28.88136,57.76271 28.88135,-57.76271 57.76273,-28.88135 z"/>
  </svg>
);

export default Star;
